import React, { useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import styles from './LastMonthChart.module.scss';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

const LastMonthChart = ({ groupedItem }) => {
  //const [chart1Labels, setChart1Labels] = useState([]);
  // const [chart1Data, setChart1Data] = useState([]);
  const [configChart1, setConfigChart1] = useState([]);
  const [configChart2, setConfigChart2] = useState([]);
  // const [readyChart1, setReadyChart1] = useState(false);
  //const [options1, setOptions] = useState([]);

  useEffect(() => {
    let labels1 = [];
    let data1 = [];
    let labels2 = [];
    let data2 = [];
    let id1 = 0;
    let id2 = 0;

    groupedItem.map((item, id) => {
      if (item.type === 'E' && item.amount !== 0) {
        labels1[id1] = item.categoryGroup;
        data1[id1] = item.amount;
        id1++;
      } else if (item.type === 'I' && item.amount !== 0) {
        labels2[id2] = item.categoryGroup;
        data2[id2] = item.amount;
        id2++;
      }
      return true;
    });

    const colors1 = [
      '#4b77a9',
      '#5f255f',
      '#d21243',
      '#B27200',
      '#5b97aa',
      '#7f357f',
      '#c21253',
      '#c27211',
      '#5b971a',
      '#7f351f',
      '#c21213',
      '#c27261',
    ];
    const colors2 = [
      'rgb(255, 199, 32)',
      'rgb(54, 162, 135)',
      'rgb(255, 205, 186)',
      'rgb(155, 99, 232)',
      'rgb(154, 162, 135)',
      'rgb(55, 205, 286)',
      'rgb(55, 99, 32)',
      'rgb(54, 162, 35)',
      'rgb(55, 205, 186)',
      'rgb(155, 199, 232)',
      'rgb(154, 62, 135)',
      'rgb(155, 105, 186)',
    ];

    let chartOptions = {
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: {
          formatter: (value, ctx) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data) => {
              return (sum += data);
            });
            let percentage = ((value * 100) / sum).toFixed(2) + '%';
            return percentage;
          },
          color: '#fff',
        },
      },
    };
    const dataChart1 = {
      labels: labels1,
      datasets: [
        {
          label: '',
          data: data1,
          backgroundColor: colors1,
          hoverOffset: 9,
        },
      ],
    };
    const dataChart2 = {
      labels: labels2,
      datasets: [
        {
          label: '',
          data: data2,
          backgroundColor: colors2,
          hoverOffset: 9,
        },
      ],
    };

    setConfigChart1({ type: 'pie', data: dataChart1, options: chartOptions });
    setConfigChart2({ type: 'doughnut', data: dataChart2, options: chartOptions });
    //setChart1Data(dataChart11);
  }, [groupedItem]);

  useEffect(() => {
    const chart1 = new Chart(document.getElementById('chart1'), configChart1);
    const chart2 = new Chart(document.getElementById('chart2'), configChart2);
    return () => {
      chart1.destroy();
      chart2.destroy();
    };
  }, [configChart1, configChart2]);

  return (
    <>
      <div className="startViewElementsWrapper" id={styles.chart1}>
        <h3>Wydatki</h3>
        <hr />
        <canvas id="chart1" className={styles.chart}></canvas>
      </div>
      <div className="startViewElementsWrapper" id={styles.chart2}>
        <h3>Przychody</h3>
        <hr />
        <canvas id="chart2" className={styles.chart}></canvas>
      </div>
    </>
  );
};

export default LastMonthChart;
