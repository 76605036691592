import React, { useState, useEffect } from 'react';

import AccountsBalanceItem from './AccountBalanceItem';
import { API } from '../../index';

import styles from './AccountBalance.module.scss';

const AccountsBalance = () => {
  const [balanceError, setBalanceError] = useState(null);
  const [balanceItem, setBalanceItem] = useState([]);
  const [isBalanceLoaded, setBalanceIsLoaded] = useState(false);
  const [balanceSummary, setBalanceSummary] = useState({});

  useEffect(() => {
    fetch(`${API}/account/balance/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setBalanceIsLoaded(true);
          setBalanceItem(result);
          let asset = 0;
          let debet = 0;
          let summary = 0;
          result.map((item) => {
            if (item.balance >= 0) {
              asset += Number(item.balance);
            } else {
              debet += Number(item.balance);
            }
            return (summary += Number(item.balance));
          });
          setBalanceSummary({
            asset,
            debet,
            summary,
          });
        },
        (balanceError) => {
          setBalanceIsLoaded(true);
          setBalanceError(balanceError);
        }
      );
  }, []);

  if (balanceError) {
    return <div>Error: {balanceError.message}</div>;
  } else if (!isBalanceLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <div className="startViewElementsWrapper">
          <h3>Bilans kont</h3>
          <hr />
          <ul>
            <li className="startViewElementsWrapperLi">
              <span>Oszczędności</span>
              <span className="startViewElementsWrapperAmount">
                {Number(balanceSummary.asset).toFixed(2)}
              </span>
            </li>
            <li className="startViewElementsWrapperLi">
              <span>Długi</span>{' '}
              <span className="startViewElementsWrapperAmount">
                {Number(balanceSummary.debet).toFixed(2)}
              </span>
            </li>
            <li className="startViewElementsWrapperLi">
              <span>Razem</span>
              <span className="startViewElementsWrapperAmount">
                {Number(balanceSummary.summary).toFixed(2)}
              </span>
            </li>
          </ul>
        </div>
        <div className="startViewElementsWrapper">
          {balanceItem.length ? (
            <>
              <h3>Salda na kontach</h3>
              <hr />
              <ul className={styles.itemList}>
                {balanceItem.map((item) => (
                  <AccountsBalanceItem key={item.id} {...item} />
                ))}
              </ul>
            </>
          ) : (
            <p className={styles.noItems}>There's nothing here yet, please add some items!</p>
          )}
        </div>
      </>
    );
  }
};

export default AccountsBalance;
