import React from 'react';

import Periodic from '../../components/Periodic/Periodic';

import styles from './PeriodicView.module.scss';

const PeriodicView = () => (
  <div className={styles.wrapper}>
    <div className={styles.itemWrapper}>
      <Periodic />
    </div>
  </div>
);

export default PeriodicView;
