import React from 'react';

import AccountsBalance from '../../components/AccountsBalance/AccountsBalance';
import FuturePayments from '../../components/FuturePayments/FuturePayments';
import LastMonth from '../../components/Transaction/LastMonth';

import styles from './StartView.module.scss';

const StartView = () => (
  <>
    <div className={styles.wrapper}>
      <div className={styles.itemWrapper1}>
        <AccountsBalance />
      </div>
      <div className={styles.itemWrapper2}>
        <FuturePayments />
      </div>
    </div>
    <div className={styles.wrapper}>
      <div className={styles.itemWrapper3}>
        <LastMonth />
      </div>
      {/* <div className={styles.itemWrapper4}>Wykresy</div> */}
    </div>
  </>
);

export default StartView;
