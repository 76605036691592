import React from 'react';

import Account from '../../components/Account/Account';

import styles from './AccountView.module.scss';

const AccountView = () => (
  <div className={styles.wrapper}>
    <div className={styles.itemWrapper}>
      <Account />
    </div>
  </div>
);

export default AccountView;
