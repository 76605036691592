import React from 'react';

//import styles from './AccountBalanceItem.module.scss';

const AccountsBalanceItem = ({ name, balance }) => {
  return (
    <li className="startViewElementsWrapperLi">
      <span>{name}</span>
      <span className="startViewElementsWrapperAmount">{Number(balance).toFixed(2)}</span>
    </li>
  );
};

export default AccountsBalanceItem;
