import React, { useState } from 'react';

import styles from './TransactionItem.module.scss';

const TransactionItem = ({
  id,
  type,
  categoryGroup,
  category,
  description,
  account,
  date,
  income,
  expense,
  amount,
  user,
  balance,
  setIsSelectedTransaction,
  dataAfterSelectedTransaction,
  categoryID,
  accountID,
}) => {
  const [previousE, setPreviousE] = useState(null);

  const onMouseEnterHandle = (e) => {
    if (
      e.target.parentElement.id !== 'divTransactionItem-0' &&
      e.target.parentElement.id !== 'divTransactionItem-undefined'
    ) {
      e.target.parentElement.style.color = 'black';
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
      e.target.parentElement.style.border = 'solid 1px #ddd';
      e.target.parentElement.style.cursor = 'pointer';
    } else if (e.target.parentElement.id === 'divTransactionItem-undefined') {
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
    }
  };

  const onMouseOutHandle = (e) => {
    if (e.target.parentElement.style.backgroundColor === 'rgb(204, 204, 204)') {
      e.target.parentElement.style.color = 'white';
    } else {
      e.target.parentElement.style.color = '#666';
      e.target.parentElement.style.fontFamily = 'Roboto-Light';
      e.target.parentElement.style.border = 'none';
    }
    if (e.target.parentElement.id === 'divTransactionItem-undefined') {
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
    }
    if (previousE) {
      document.getElementById(previousE).style.color = '#666';
      document.getElementById(previousE).style.fontFamily = 'Roboto-Light';
    }
  };

  const onMouseDown = (e) => {
    if (
      e.target.parentElement.id !== 'divTransactionItem-0' &&
      e.target.parentElement.id !== 'divTransactionItem-undefined'
    ) {
      e.target.parentElement.style.backgroundColor = 'rgb(224, 224, 224)';
      e.target.parentElement.style.color = 'black';

      if (e.target.parentElement.id !== previousE) {
        setPreviousE(e.target.parentElement.id);
      }

      dataAfterSelectedTransaction({
        id,
        account,
        type,
        category,
        description,
        date,
        income,
        expense,
        user,
        categoryID,
        accountID,
      });
      setIsSelectedTransaction(e.target.parentElement.id);
    }
  };
  return (
    <div className={styles.wrapper} id={'divTransactionItem-' + id}>
      <span
        className={styles.itemDate}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {date}
      </span>
      <span
        className={styles.itemAccount}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {account}
      </span>
      <span
        className={styles.itemCategoryGroup}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {categoryGroup}
      </span>
      <span
        className={styles.itemCategory}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {category}
      </span>
      <span
        className={styles.itemDescription}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {description}
      </span>
      <span
        className={styles.itemIncome}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {income}
      </span>
      <span
        className={styles.itemExpense}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {expense}
      </span>
      <span
        className={styles.itemBalance}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {balance !== 'Saldo' ? Number(balance).toFixed(2) : balance}
      </span>
    </div>
  );
};

export default TransactionItem;
