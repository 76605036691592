import React, { useState, useEffect } from 'react';

import { API } from '../../index';

import styles from './Account.module.scss';

const Account = () => {
  const [isAccountLoaded, setIsAccountLoaded] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [isAccountTypeLoaded, setIsAccountTypeLoaded] = useState(false);
  const [accountTypeError, setAccountTypeError] = useState(null);
  const [account, setAccount] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [accountReload, setAccountReload] = useState(new Date());
  const [accountTypeReload, setAccountTypeReload] = useState(new Date());
  const [accountLocalReload, setAccountLocalReload] = useState(new Date());

  useEffect(() => {
    fetch(`${API}/account/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAccountLoaded(true);
          setAccount(result);
        },
        (error) => {
          setIsAccountLoaded(true);
          setAccountError(error);
        }
      );
  }, [accountReload]);

  useEffect(() => {
    fetch(`${API}/account/type/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAccountTypeLoaded(true);
          setAccountType(result);
        },
        (error) => {
          setIsAccountTypeLoaded(true);
          setAccountTypeError(error);
        }
      );
  }, [accountTypeReload]);

  //tylko do odświeżenia i wczytania kont ze useState account
  useEffect(() => {
    // console.log('useEffect reload');
  }, [accountLocalReload]);

  if (accountTypeError || accountError) {
    return (
      <div>
        Error: {accountTypeError.message} {accountError.message}
      </div>
    );
  } else if (!isAccountTypeLoaded || !isAccountLoaded) {
    return <div>Loading...</div>;
  } else {
    //  console.log(account);
    //  console.log(accountType);

    /* ACCOUNT MODIFY */

    /* POST  - add account */
    const addAccountHandle = (e) => {
      e.preventDefault();
      const name = document.getElementById('newAccountName');
      if (!name.value) {
        alert('Podaj nawę konta przez zapisaniem');
      } else {
        const accountType = document.getElementById('changeNewTypeAccount').value;
        const number = document.getElementById('newAccountNumber');
        const data = {
          name: name.value,
          accountType,
          number: number.value,
          date: '',
          status: 'A',
          user: 1,
        };
        fetch(`${API}/account`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }).then((result) => {
          name.value = '';
          number.value = '';
          setAccountReload(new Date());
        });
      }
    };

    /* PUT - update account */
    const accountHandle = (e, id) => {
      e.preventDefault();
      const name = document.getElementById('accountName' + id);
      if (!name.value) {
        alert('Podaj nawę konta przed zapisaniem');
      } else {
        const accountType = document.getElementById('selectAccount' + id).value;
        const number = document.getElementById('accontNumber' + id);
        const data = {
          id,
          name: name.value,
          accountType,
          number: number.value,
          date: '',
          status: 'A',
          user: 1,
        };
        fetch(`${API}/account`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }).then((result) => {
          setAccountReload(new Date());
        });
      }
    };

    /* DELETE account */
    const deleteAccountHandle = (e, id) => {
      e.preventDefault();
      const data = {
        user: 1,
        id,
      };
      fetch(`${API}/account/checkDelete`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result[0].records) {
            alert('Nie można usunąć, konto posiada transakcje');
          } else {
            if (window.confirm('Czy na pewno chcesz usunąć konto?')) {
              fetch(`${API}/account`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
              }).then((result) => {
                setAccountReload(new Date());
              });
            }
          }
        });
    };
    /* ACCOUNT TYPE MODIFY */

    /* POST  - add type account */
    const addAccountTypeHandle = (e) => {
      e.preventDefault();
      const name = document.getElementById('newAccountTypeName');
      if (!name.value) {
        alert('Podaj nawę typu konta przez zapisaniem');
      } else {
        const data = {
          name: name.value,
          user: 1,
        };
        fetch(`${API}/account/type`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }).then((result) => {
          name.value = '';
          setAccountReload(new Date());
          setAccountTypeReload(new Date());
        });
      }
    };

    /* PUT - update  account type */
    const accountTypeHandle = (e, id) => {
      e.preventDefault();
      const name = document.getElementById('accountTypeName' + id);
      if (!name.value) {
        alert('Podaj nawę typu konta przed zapisaniem');
      } else {
        const data = {
          id,
          name: name.value,
          user: 1,
        };
        fetch(`${API}/account/type`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        }).then((result) => {
          setAccountReload(new Date());
          setAccountTypeReload(new Date());
        });
      }
    };

    /* DELETE account type*/
    const deleteAccountTypeHandle = (e, id) => {
      e.preventDefault();
      const data = {
        user: 1,
        id,
      };
      fetch(`${API}/account/type/checkDelete`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result[0].records) {
            alert('Nie można usunąć, typ posiada konta');
          } else {
            if (window.confirm('Czy na pewno chcesz usunąć typ konta?')) {
              fetch(`${API}/account/type`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
              }).then((result) => {
                setAccountReload(new Date());
                setAccountTypeReload(new Date());
              });
            }
          }
        });
    };

    const getSelectedAccount = (id, idRek, e) => {
      const selectedAccount = document.getElementById('selectAccount' + id);
      account[idRek].accountTypeID = selectedAccount.value;
      account[idRek].accountType = e.target.options[e.target.selectedIndex].text;
      setAccountLocalReload(new Date());
    };

    return (
      <div className={styles.wrapper}>
        <div className="startViewElementsWrapper">
          <h3>Modyfikacja kont</h3>
          <form>
            <input
              placeholder="Nazwa konta"
              id="newAccountName"
              type="text"
              className={styles.accountName}
            />
            <select
              id="changeNewTypeAccount"
              onChange={getSelectedAccount}
              className={styles.accountType}
            >
              {accountType.map((itemType) => (
                <option key={itemType.id} value={itemType.id}>
                  {itemType.name}
                </option>
              ))}
            </select>
            <input
              placeholder="Numer konta"
              id="newAccountNumber"
              type="text"
              className={styles.accountNumber}
            />
            <button onClick={addAccountHandle}>Dodaj nowy</button>
          </form>
          <br />
          {account.map((item, idRek) => (
            <form key={item.id}>
              <input
                defaultValue={item.name}
                placeholder="Nazwa konta"
                type="text"
                id={'accountName' + item.id}
                className={styles.accountName}
              />
              <select
                id={'selectAccount' + item.id}
                onChange={(e) => getSelectedAccount(item.id, idRek, e)}
                value={item.accountTypeID}
                className={styles.accountType}
              >
                {accountType.map((itemType) => (
                  <option key={itemType.id} value={itemType.id}>
                    {itemType.name}
                  </option>
                ))}
              </select>
              <input
                defaultValue={item.account}
                placeholder="Numer konta"
                type="text"
                id={'accontNumber' + item.id}
                className={styles.accountNumber}
              />
              <button onClick={(e) => accountHandle(e, item.id)}>Zapisz</button>
              <button onClick={(e) => deleteAccountHandle(e, item.id)}>Usuń</button>
            </form>
          ))}
        </div>
        <div className="startViewElementsWrapper">
          <h3>Modyfikacja typów kont</h3>
          <form>
            <input
              placeholder="Nazwa typu konta"
              id="newAccountTypeName"
              type="text"
              className={styles.accountName}
            />
            <button onClick={addAccountTypeHandle}>Dodaj nowy</button>
          </form>
          <br />
          {accountType.map((item) => (
            <form key={item.id}>
              <input
                defaultValue={item.name}
                placeholder="Nazwa typu konta"
                type="text"
                className={styles.accountName}
                id={'accountTypeName' + item.id}
              />
              <button onClick={(e) => accountTypeHandle(e, item.id)}>Zapisz</button>
              <button onClick={(e) => deleteAccountTypeHandle(e, item.id)}>Usuń</button>
            </form>
          ))}
        </div>
      </div>
    );
  }
};

export default Account;
