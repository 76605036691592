import React, { useState } from 'react';

import styles from './PeriodicItem.module.scss';

const PeriodicItem = ({
  id,
  name,
  account,
  accountID,
  type,
  category,
  categoryID,
  amount,
  description,
  frequency,
  dayOfLastPayment,
  dayOfEndPayment,
  dayOfFirstPayment,
  indefinite,
  user,
  dataAfterSelected,
  setIsSelectedPeriodic,
}) => {
  const [previousE, setPreviousE] = useState(null);

  const onMouseEnterHandle = (e) => {
    if (e.target.parentElement.id !== 'divPeriodicItem-undefined') {
      e.target.parentElement.style.color = 'black';
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
      e.target.parentElement.style.border = 'solid 1px #ddd';
      e.target.parentElement.style.cursor = 'pointer';
    } else if (e.target.parentElement.id === 'divPeriodicItem-undefined') {
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
    }
  };

  const onMouseOutHandle = (e) => {
    if (e.target.parentElement.style.backgroundColor === 'rgb(204, 204, 204)') {
      e.target.parentElement.style.color = 'white';
    } else {
      e.target.parentElement.style.color = '#666';
      e.target.parentElement.style.fontFamily = 'Roboto-Light';
      e.target.parentElement.style.border = 'none';
    }
    if (e.target.parentElement.id === 'divPeriodicItem-undefined') {
      e.target.parentElement.style.fontFamily = 'Roboto-Medium';
    }
    if (previousE) {
      document.getElementById(previousE).style.color = '#666';
      document.getElementById(previousE).style.fontFamily = 'Roboto-Light';
    }
  };

  const onMouseDown = (e) => {
    if (e.target.parentElement.id !== 'divPeriodicItemItem-undefined') {
      e.target.parentElement.style.backgroundColor = 'rgb(224, 224, 224)';
      e.target.parentElement.style.color = 'black';

      if (e.target.parentElement.id !== previousE) {
        setPreviousE(e.target.parentElement.id);
      }
      dataAfterSelected({
        id,
        account,
        accountID,
        type,
        name,
        category,
        categoryID,
        description,
        dayOfEndPayment,
        dayOfFirstPayment,
        dayOfLastPayment,
        indefinite,
        frequency,
        amount,
        user,
      });
      setIsSelectedPeriodic(e.target.parentElement.id);
    }
  };
  let lastDate = '';
  id
    ? (lastDate = new Date(dayOfLastPayment).toISOString().split('T')[0])
    : (lastDate = dayOfLastPayment);
  return (
    <div className={styles.wrapper} id={'divPeriodicItem-' + id}>
      <span
        className={styles.itemName}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {name}
      </span>
      <span
        className={styles.itemAccount}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {account}
      </span>
      <span
        className={styles.itemCategory}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {category}
      </span>
      <span
        className={styles.itemDescription}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {description}
      </span>
      <span
        className={styles.itemFrequency}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {frequency}
      </span>
      <span
        className={styles.itemAmount}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {amount}
      </span>
      <span
        className={styles.itemLastDate}
        onMouseEnter={onMouseEnterHandle}
        onMouseOut={onMouseOutHandle}
        onMouseDown={onMouseDown}
      >
        {lastDate}
      </span>
    </div>
  );
};

export default PeriodicItem;
