import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "../../components/Header/Header";
import StartView from "../StartView/StartView";
import TransactionView from "../TransactionView/TransactionView";
import CategoryView from "../CategoryView/CategoryView";
import AccountView from "../AccountView/AccountView";
import PeriodicView from "../PeriodicView/PeriodicView";

//import Login from "../../components/Login/Login";
//import useToken from "../../components/CustomHooks/useToken";

//import React, { useState } from 'react/cjs/react.production.min';
//import styles from './Root.module.scss'

//class Root extends React.Component

function Root() {
  //const [token, setToken] = useState();
  // const { token, setToken } = useToken();

  //if (!token) {
  //  return <Login setToken={setToken} />;
  //}

  return (
    <BrowserRouter>
      <>
        <Header />
        <Routes>
          <Route path="/" element={<StartView />} />
          <Route path="/transactions" element={<TransactionView />} />
          <Route path="/categories" element={<CategoryView />} />
          <Route path="/accounts" element={<AccountView />} />
          <Route path="/periodics" element={<PeriodicView />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default Root;
