import React, { useState, useEffect } from 'react';

import MCDatepicker from 'mc-datepicker';
import { customWeekDays, customMonths } from '../../../general';
import CategoryTree from '../../Category/Category';

import styles from './TransactionModal.module.scss';

const Modal = ({ transactionModalClose, updateTransactionData, account, transactionData }) => {
  const [categoryName, setCategoryName] = useState(
    transactionData.id ? transactionData.category : ''
  );
  const [fromDatePicker, setFromDatePicker] = useState(new Date().toISOString().split('T')[0]);
  const [selectedCategory, setSelectedCategory] = useState(
    transactionData.id ? transactionData.categoryID : null
  );
  const [validateLabel, setValidateLabel] = useState(null);
  const [description, setDescription] = useState(
    transactionData.id ? transactionData.description : ''
  );
  const [amount, setAmount] = useState(
    transactionData.id
      ? Number(transactionData.income)
        ? transactionData.income
        : transactionData.expense
      : ''
  );
  const [selectedAccount, setSelectedAccount] = useState(
    transactionData.id ? transactionData.accountID : ''
  );
  const [transactionType, setTransactionType] = useState(
    transactionData.id ? (transactionData.type === 'I' ? 'income' : 'expense') : 'expense'
  );

  useEffect(() => {
    transactionData.id
      ? setFromDatePicker(new Date(transactionData.date).toISOString().split('T')[0])
      : setFromDatePicker(new Date().toISOString().split('T')[0]);
  }, [transactionData.id, transactionData.date]);

  const handleInputFromClick = () => fromPicker.open();

  const fromPicker = MCDatepicker.create({
    el: '#datepickerTransactionFrom',
    bodyType: 'inline',
    dateFormat: 'yyyy-mm-dd',
    autoClose: true,
    customWeekDays,
    customMonths,
    customClearBTN: '',
    customCancelBTN: '',
    firstWeekday: 1,
    closeOnBlur: true,
    selectedDate: new Date(fromDatePicker),
  });

  fromPicker.onClose(() => setFromDatePicker(fromPicker.getFormatedDate()));
  fromPicker.onSelect(() => setFromDatePicker(fromPicker.getFormatedDate()));

  const getTransactionDataHandle = (e) => {
    e.preventDefault();
    const type = transactionType === 'expense' ? 'E' : 'I';
    const amount = Number(
      Number(document.getElementById('amount').value.replace(/,/g, '.')).toFixed(2)
    );
    const income = type === 'I' ? amount : 0;
    const expense = type === 'E' ? amount : 0;

    if (selectedCategory && amount) {
      updateTransactionData(
        {
          id: transactionData.id ? transactionData.id : 0,
          account: Number(document.getElementById('selectAccount').value),
          type,
          category: selectedCategory,
          description: document.getElementById('description').value,
          date: fromDatePicker,
          income,
          expense,
          user: 1,
        },
        transactionData.id ? 'update' : 'add'
      );
      transactionModalClose(e);
    } else {
      setValidateLabel('Nie wybrano kategorii i/lub nie wpisano kwoty');
    }
  };

  const inputDescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const inputAmountHandle = (e) => {
    setAmount(e.target.value);
  };

  const comboboxHandle = (e) => {
    setSelectedAccount(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span></span>
        <span>
          <label>{transactionData.id ? 'Modyfikacja transakcji' : 'Dodawanie transakcji'}</label>
        </span>
        <span>
          <button className={styles.closeButton} onClick={transactionModalClose}>
            X
          </button>
        </span>
      </div>
      <div className={styles.wrapperInside}>
        <CategoryTree
          setSelectedCategory={setSelectedCategory}
          setCategoryName={setCategoryName}
          transactionData={transactionData}
          setTransactionTypeFromCategory={setTransactionType}
        />

        <div className={styles.calendarForm}>
          <form className={styles.transactionFromModal}>
            <div>
              <div>
                <label className={styles.transactionFromLabel} htmlFor="datepickerTransactionFrom">
                  Data
                </label>
                <input
                  id="datepickerTransactionFrom"
                  className={styles.transactionFromInput}
                  type="text"
                  readOnly
                  onClick={handleInputFromClick}
                  value={fromDatePicker}
                />
              </div>
              <div>
                <label className={styles.transactionFromLabel} htmlFor="selectAccount">
                  Konto
                </label>
                <select
                  id="selectAccount" //{styles.selectAccount}
                  className={styles.transactionFromInput}
                  value={selectedAccount}
                  onChange={comboboxHandle}
                >
                  {account.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className={styles.transactionFromLabel} htmlFor="category">
                  Kategoria
                </label>
                <input
                  type="text"
                  placeholder=""
                  name="category"
                  id="category"
                  className={styles.transactionFromInput}
                  value={categoryName}
                  disabled
                />
              </div>
              <div id={styles.descriptionDiv}>
                <label className={styles.transactionFromLabel} htmlFor="description">
                  Opis
                </label>
                <textarea
                  rows="4"
                  placeholder=""
                  name="description"
                  id="description" //{styles.description}
                  className={styles.transactionFromInput}
                  value={description}
                  onChange={inputDescriptionHandler}
                />
              </div>
              <div>
                <label className={styles.transactionFromLabel} htmlFor="amount">
                  Kwota
                </label>
                <input
                  type="text"
                  placeholder=""
                  name="amount"
                  id="amount"
                  className={styles.transactionFromInput}
                  value={amount}
                  onChange={inputAmountHandle}
                />

                {validateLabel && <label htmlFor="amount">{validateLabel}</label>}
              </div>
            </div>
            <div className={styles.transactionFromGroupButtons}>
              <button onClick={getTransactionDataHandle}>Zapisz</button>
              <button onClick={transactionModalClose}>Anuluj</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
