import React, { useState, useEffect } from 'react';

import FuturePaymentsItem from './FuturePaymentsItem';
import { API } from '../../index';
import styles from './FuturePayments.module.scss';

const FuturePayments = () => {
  const [futurePaymentsError, setFuturePaymentsError] = useState(null);
  const [futurePaymentsItem, setFuturePaymentsItem] = useState([]);
  const [isfuturePaymentsLoaded, setFuturePaymentsIsLoaded] = useState(false);

  useEffect(() => {
    fetch(`${API}/futurePayments/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setFuturePaymentsIsLoaded(true);
          setFuturePaymentsItem(result);
        },
        (balanceError) => {
          setFuturePaymentsIsLoaded(true);
          setFuturePaymentsError(balanceError);
        }
      );
  }, []);

  if (futurePaymentsError) {
    return <div>Error: {futurePaymentsError.message}</div>;
  } else if (!isfuturePaymentsLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="startViewElementsWrapper">
        {futurePaymentsItem.length ? (
          <>
            <h3>Przyszłe dochody i opłaty</h3>
            <hr />
            <ul className={styles.itemList}>
              {futurePaymentsItem.map((item) => (
                <FuturePaymentsItem key={item.id} {...item} />
              ))}
            </ul>
          </>
        ) : (
          <p className={styles.noItems}>There's nothing here yet, please add some items!</p>
        )}
      </div>
    );
  }
};

export default FuturePayments;
