import React from 'react';

import styles from './FuturePaymentsItem.module.scss';

const FuturePaymentsItem = ({ categoryGroup, category, description, account, date, amount }) => {
  return (
    <li className={styles.wrapper}>
      <span className={styles.itemCategoryGroup}>{categoryGroup}</span>
      <span className={styles.itemCategory}>{category}</span>
      <span className={styles.itemDescription}>{description}</span>
      <span className={styles.itemAccount}>{account}</span>
      <span className={styles.itemDate}>{date}</span>
      <span className={styles.itemAmount}>{amount}</span>
    </li>
  );
};

export default FuturePaymentsItem;
