import React, { useState, useEffect } from 'react';

import MCDatepicker from 'mc-datepicker';
import { customWeekDays, customMonths } from '../../general';
import CategoryTree from '../Category/Category';
import { API } from '../../index';

import styles from './PeriodicModal.module.scss';

const PeriodicModal = ({ setIsPeriodicModalOpen, periodicData, setReload }) => {
  const [categoryName, setCategoryName] = useState(periodicData.category);
  const [selectedCategory, setSelectedCategory] = useState(periodicData.categoryID);
  const [transactionType, setTransactionType] = useState(
    periodicData.type === 'I' ? 'income' : 'expense'
  );
  const [isAccountLoaded, setIsAccountLoaded] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [account, setAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(
    periodicData.accountID ? periodicData.accountID : 0
  );
  const [dayOfEndPaymentDatePicker, setDayOfEndPaymentDatePicker] = useState(
    periodicData.dayOfEndPayment
      ? new Date(periodicData.dayOfEndPayment).toISOString().split('T')[0]
      : new Date().toISOString().split('T')[0]
  );
  const [dayOfFirstPaymentDatePicker, setDayOfFirstPaymentDatePicker] = useState(
    periodicData.dayOfFirstPayment
      ? new Date(periodicData.dayOfFirstPayment).toISOString().split('T')[0]
      : new Date().toISOString().split('T')[0]
  );

  const [indefiniteData, setIndefiniteData] = useState(
    periodicData.indefinite ? periodicData.indefinite : 0
  );

  useEffect(() => {
    fetch(`${API}/account/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAccountLoaded(true);
          setAccount(result);
        },
        (error) => {
          setIsAccountLoaded(true);
          setAccountError(error);
        }
      );
  }, []);

  const dayOfEndPaymentPicker = MCDatepicker.create({
    el: '#dayOfEndPayment',
    bodyType: 'inline',
    dateFormat: 'yyyy-mm-dd',
    autoClose: true,
    customWeekDays,
    customMonths,
    customClearBTN: '',
    customCancelBTN: '',
    firstWeekday: 1,
    closeOnBlur: true,
    selectedDate: new Date(dayOfEndPaymentDatePicker),
  });

  const dayOfFirstPaymentPicker = MCDatepicker.create({
    el: '#dayOfFirstPayment',
    bodyType: 'inline',
    dateFormat: 'yyyy-mm-dd',
    autoClose: true,
    customWeekDays,
    customMonths,
    customClearBTN: '',
    customCancelBTN: '',
    firstWeekday: 1,
    closeOnBlur: true,
    selectedDate: new Date(dayOfFirstPaymentDatePicker),
  });

  dayOfEndPaymentPicker.onClose(() =>
    setDayOfEndPaymentDatePicker(dayOfEndPaymentPicker.getFormatedDate())
  );
  dayOfEndPaymentPicker.onSelect(() =>
    setDayOfEndPaymentDatePicker(dayOfEndPaymentPicker.getFormatedDate())
  );
  dayOfFirstPaymentPicker.onClose(() =>
    setDayOfFirstPaymentDatePicker(dayOfFirstPaymentPicker.getFormatedDate())
  );
  dayOfFirstPaymentPicker.onSelect(() =>
    setDayOfFirstPaymentDatePicker(dayOfFirstPaymentPicker.getFormatedDate())
  );

  const cancelHandle = (e) => {
    // e.preventDefault();
    setIsPeriodicModalOpen(false);
  };

  const saveHandle = (e) => {
    const type = transactionType === 'expense' ? 'E' : 'I';
    const data = {
      account: selectedAccount,
      type,
      name: document.getElementById('name').value,
      category: selectedCategory,
      description: document.getElementById('description').value,
      dayOfEndPayment: document.getElementById('dayOfEndPayment').value,
      dayOfFirstPayment: document.getElementById('dayOfFirstPayment').value,
      indefinite: indefiniteData,
      frequency: document.getElementById('frequency').value,
      amount: Number(Number(document.getElementById('amount').value.replace(/,/g, '.')).toFixed(2)),
      user: 1,
      id: periodicData.id,
    };

    fetch(`${API}/periodic`, {
      method: periodicData.id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    setIsPeriodicModalOpen(false);
    setTimeout(() => setReload(new Date()), 500);
  };

  const comboboxHandle = (e) => {
    setSelectedAccount(e.target.value);
  };

  const indefiniteHandle = () => {
    setIndefiniteData(!indefiniteData);
    // indefiniteData
    //   ? (document.getElementById('dayOfEndPayment').style.visibility = 'visible')
    //   : (document.getElementById('dayOfEndPayment').style.visibility = 'hidden');
  };

  if (accountError) {
    return <div>Error: {accountError.message}</div>;
  } else if (!isAccountLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <span></span>
          <span>
            <label>
              {periodicData.id
                ? 'Modyfikacja cyklicznej operacji'
                : 'Dodawanie  cyklicznej operacji'}
            </label>
          </span>
          <span>
            <button className={styles.closeButton} onClick={cancelHandle}>
              X
            </button>
          </span>
        </div>
        <div className={styles.wrapperInside}>
          <CategoryTree
            setSelectedCategory={setSelectedCategory}
            setCategoryName={setCategoryName}
            transactionData={periodicData}
            setTransactionTypeFromCategory={setTransactionType}
          />

          <div className={styles.periodicFormModal}>
            <div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="name">
                  Nazwa
                </label>
                <input
                  placeholder="Nazwa operacji"
                  className={styles.periodicFromInput}
                  type="text"
                  id="name"
                  defaultValue={periodicData.name ? periodicData.name : ''}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="description">
                  Opis
                </label>
                <input
                  placeholder="Opis"
                  className={styles.periodicFromInput}
                  type="text"
                  id="description"
                  defaultValue={periodicData.description ? periodicData.description : ''}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="category">
                  Kategoria
                </label>
                <input
                  placeholder="Wybrana kategoria"
                  className={styles.periodicFromInput}
                  type="text"
                  id="category"
                  disabled
                  defaultValue={categoryName}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="selectAccount">
                  Konto
                </label>
                <select
                  id="selectAccount"
                  className={styles.periodicFromInput}
                  value={selectedAccount}
                  onChange={comboboxHandle}
                >
                  {account.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  className={styles.periodicFromLabel}
                  htmlFor="indefinite"
                  id={styles.indefiniteLabel}
                >
                  Bezterminowo
                </label>
                <input
                  type="checkbox"
                  className={styles.periodicFromInput}
                  id="indefinite"
                  onChange={indefiniteHandle}
                  checked={indefiniteData}
                />
              </div>
              <div>
                <label
                  className={indefiniteData ? styles.hidden : styles.periodicFromLabel}
                  htmlFor="dayOfEndPayment"
                >
                  Koniec
                </label>
                <input
                  placeholder="Dzień końca płatności"
                  type="text"
                  id="dayOfEndPayment"
                  defaultValue={dayOfEndPaymentDatePicker}
                  className={indefiniteData ? styles.hidden : styles.periodicFromInput}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="dayOfFirstPayment">
                  Początek
                </label>
                <input
                  placeholder="Dzień pierwszej płatności"
                  className={styles.periodicFromInput}
                  type="text"
                  id="dayOfFirstPayment"
                  defaultValue={dayOfFirstPaymentDatePicker}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="dayOfLastPayment">
                  Ostatnio
                </label>
                <input
                  placeholder="Ostatnia płatność"
                  className={styles.periodicFromInput}
                  type="text"
                  disabled
                  id="dayOfLastPayment"
                  defaultValue={
                    periodicData
                      ? periodicData.dayOfLastPayment
                        ? new Date(periodicData.dayOfLastPayment).toISOString().split('T')[0]
                        : null
                      : null
                  }
                />
              </div>
              <div>
                <label
                  className={styles.periodicFromLabel}
                  htmlFor="frequency"
                  id={styles.frequencyLabel}
                >
                  Częstotliwość
                </label>
                <input
                  placeholder="Częstotliwość (co ile miesięcy)"
                  className={styles.periodicFromInput}
                  type="text"
                  id="frequency"
                  defaultValue={periodicData.frequency ? periodicData.frequency : 1}
                />
              </div>
              <div>
                <label className={styles.periodicFromLabel} htmlFor="amount">
                  Wartość
                </label>
                <input
                  placeholder="Wartość"
                  className={styles.periodicFromInput}
                  type="text"
                  id="amount"
                  defaultValue={periodicData.amount ? periodicData.amount : 0}
                />
              </div>
            </div>
            <div className={styles.transactionFromGroupButtons}>
              <button onClick={saveHandle}>Zapisz</button>
              <button onClick={cancelHandle}>Anuluj</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PeriodicModal;
