import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./HeaderNavigation.module.scss";
import useToken2 from "../../components/CustomHooks/useToken";
import Login from "../../components/Login/Login";

const HeaderNavigation = () => {
  const { token, setToken } = useToken2();

  const logout = () => {
    //localStorage.removeItem("token");

    if (token) {
      //setTokenDel(tokenDel);
      return <Login setToken={setToken(token)} />;
    }
  };

  return (
    <nav>
      <ul className={styles.wrapper}>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/"
          >
            Start
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/transactions"
          >
            Transactions
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/periodics"
          >
            Periodics
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/categories"
          >
            Categories
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/accounts"
          >
            Accounts
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink
            className={(navData) =>
              navData.isActive
                ? styles.navItemNavLinkActive
                : styles.navItemNavLink
            }
            to="/logout"
            onClick={logout}
          >
            Logout
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
