import React, { useState, useEffect } from 'react';

import LastMonthItem from './LastMonthItem';
import LastMonthChart from '../Chart.js/LastMonthChart';
import { API } from '../../index';
import { groupBy } from '../../general';

import styles from './LastMonth.module.scss';

const LastMonth = () => {
  const [LastMonthError, setLastMonthError] = useState(null);
  // const [LastMonthItem, setLastMonthItem] = useState([]);
  const [isLastMonthLoaded, setIsLastMonthLoaded] = useState(false);
  const [groupedItem, setGroupedItem] = useState([]);

  useEffect(() => {
    fetch(`${API}/transaction/lastMonth/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLastMonthLoaded(true);

          const categoryGroupGrouped = groupBy(result, 'categoryGroup');

          const groupedItemLoc = [];

          Object.keys(categoryGroupGrouped).map((item, id) =>
            result.map((item2) => {
              const { account, amount, category, date, description, type } = item2;

              if (item2.categoryGroup === item) {
                if (groupedItemLoc[id]) {
                  groupedItemLoc[id] = {
                    categoryGroup: item,
                    amount: groupedItemLoc[id]['amount'] + Number(item2.amount),
                    type,
                    transaction: groupedItemLoc[id]['transaction'].concat([
                      {
                        account,
                        amount,
                        category,
                        date,
                        description,
                        id: item2.id,
                      },
                    ]),
                  };
                } else {
                  groupedItemLoc[id] = {
                    categoryGroup: item,
                    amount: Number(item2.amount),
                    type,
                    transaction: [
                      {
                        account,
                        amount,
                        category,
                        date,
                        description,
                        id: item2.id,
                      },
                    ],
                  };
                }
                // if (groupedItemLoc[item]) {
                //   groupedItemLoc[item] += Number(item2.amount);
                // } else {
                //   groupedItemLoc[item] = Number(item2.amount);
                // }
              }
              return true;
            })
          );
          setGroupedItem(groupedItemLoc);
        },
        (error) => {
          setIsLastMonthLoaded(true);
          setLastMonthError(error);
        }
      );
  }, []);

  if (LastMonthError) {
    return <div>Error: {LastMonthError.message}</div>;
  } else if (!isLastMonthLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={styles.LastMonthWrapper}>
        <div className="startViewElementsWrapper" id={styles.lastMonthItem1}>
          {groupedItem.length && (
            <>
              <ul>
                <h3>Ostatnie operacje</h3>
                <hr />
                <h4>Wydatki</h4>
                {groupedItem.map((item, id) => (
                  <div key={id}>
                    {id !== 0 && <hr className={styles.hrGroup} />}
                    {item.type === 'E' && (
                      <LastMonthItem
                        key={id}
                        categoryGroup={item.categoryGroup}
                        amount={item.amount}
                        transaction={item.transaction}
                      />
                    )}
                  </div>
                ))}
                <hr />
                <h4>Przychody</h4>
                {groupedItem.map((item, id) => (
                  <div key={id}>
                    {id !== 0 && <hr className={styles.hrGroup} />}
                    {item.type === 'I' && (
                      <LastMonthItem
                        key={id}
                        categoryGroup={item.categoryGroup}
                        amount={item.amount}
                        transaction={item.transaction}
                      />
                    )}
                  </div>
                ))}
              </ul>
            </>
          )}
        </div>
        <div>
          <LastMonthChart groupedItem={groupedItem} />
        </div>
      </div>
    );
  }
};

export default LastMonth;
