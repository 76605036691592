import React, { useState, useEffect } from 'react';

import { API } from '../../index';
import PeriodicItem from './PeriodicItem';
import PeriodicModal from './PeriodicModal';

import styles from './Periodic.module.scss';

const Periodic = () => {
  const [isPeriodicLoaded, setIsPeriodicLoadred] = useState(false);
  const [periodicError, setPeriodicError] = useState(null);
  const [periodic, setPeriodic] = useState([]);
  const [periodicData, setPeriodicData] = useState([]);
  const [isSelectedPeriodic, setIsSelectedPeriodic] = useState(false);
  const [isPeriodicModalOpen, setIsPeriodicModalOpen] = useState(false);
  const [reload, setReload] = useState(new Date());

  useEffect(() => {
    fetch(`${API}/periodic/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsPeriodicLoadred(true);
          setPeriodic(result);
        },
        (error) => {
          setIsPeriodicLoadred(true);
          setPeriodicError(error);
        }
      );
    //console.log('reload');
  }, [reload]);

  //   useEffect(() => {
  //     unselectPeriodic();
  //   }, [isSelectedPeriodic]);

  const periodicModalOpenAddHandle = () => {
    setPeriodicData([]);
    setIsPeriodicModalOpen(true);
    unselectPeriodic();
  };
  const periodicModalOpenEditHandle = () => {
    setIsPeriodicModalOpen(true);
    unselectPeriodic();
  };
  const periodicDeleteHandle = (e) => {
    e.preventDefault();
    if (window.confirm('Czy na pewno chcesz usunąć operację?')) {
      fetch(`${API}/periodic`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(periodicData),
      });
      setPeriodicData([]);
      unselectPeriodic();
      setTimeout(() => setReload(new Date()), 500);
    }
  };

  const unselectPeriodic = () => {
    if (isSelectedPeriodic) document.getElementById(isSelectedPeriodic).style.backgroundColor = '';
    setIsSelectedPeriodic(false);
  };

  const dataAfterSelected = (data) => {
    if (isSelectedPeriodic && periodicData.id !== data.id) {
      document.getElementById(isSelectedPeriodic).style.backgroundColor = '';
    }
    setPeriodicData(data);
  };

  if (periodicError) {
    return <div>Error: {periodicError.message}</div>;
  } else if (!isPeriodicLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="startViewElementsWrapper" id={styles.div}>
        <h3>Operacje cykliczne</h3>
        <button onClick={periodicModalOpenAddHandle}>Dodaj</button>
        {isSelectedPeriodic && (
          <>
            <button onClick={periodicModalOpenEditHandle}>Edytuj</button>
            <button onClick={periodicDeleteHandle}>Usuń</button>
          </>
        )}
        <br />

        {periodic.length ? (
          <>
            <PeriodicItem
              name="Nazwa"
              account="Konto"
              category="Kategoria"
              description="Opis"
              frequency="Częstotliwość"
              amount="Kwota"
              dayOfLastPayment="Wykonano"
            />
            {periodic.map((item) => (
              <div key={item.id}>
                <PeriodicItem
                  {...item}
                  dataAfterSelected={dataAfterSelected}
                  setIsSelectedPeriodic={setIsSelectedPeriodic}
                />
                {/* {item.account} {item.type} {item.name} {item.category} {item.description}{' '}
                {item.dayOfEndPayment} {item.dayOfFirstPayment} {item.dayOfLastPayment}{' '}
                {item.indefinite} {item.frequency} {item.amount} {item.user} */}
              </div>
            ))}
          </>
        ) : (
          <p className={styles.noItems}>W wybranym okresie nie ma żadnych danych!</p>
        )}
        {isPeriodicModalOpen && (
          <PeriodicModal
            setIsPeriodicModalOpen={setIsPeriodicModalOpen}
            periodicData={periodicData}
            setReload={setReload}
          />
        )}
      </div>
    );
  }
};

export default Periodic;
