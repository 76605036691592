import React from 'react';

import CategoryTree from '../../components/Category/Category';

import styles from './CategoryView.module.scss';

const CategoryView = () => (
  <div className={styles.wrapper}>
    <div className={styles.itemWrapper}>
      <CategoryTree separateInstance={true} />
    </div>
  </div>
);

export default CategoryView;
