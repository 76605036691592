import React, { useState, useEffect } from 'react';

import { API } from '../../index';
import { groupBy } from '../../general';

import styles from './Category.module.scss';

const Category = ({
  setSelectedCategory,
  setCategoryName,
  transactionData,
  setTransactionTypeFromCategory,
  separateInstance,
}) => {
  const [isTransactionTypeLoaded, setIsTransactionTypeLoaded] = useState(false);
  const [transactionCategory, setTransactionCategory] = useState([]);
  const [transactionError, setTransactionError] = useState(null);
  const [transactionType, setTransactionType] = useState(
    transactionData ? (transactionData.type === 'I' ? 'income' : 'expense') : 'expense'
  );
  const [categoryTreeReload, setCategoryTreeReload] = useState(new Date());
  const [editMode, setEditMode] = useState(separateInstance ? true : false);

  useEffect(() => {
    fetch(`${API}/category/${transactionType}/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsTransactionTypeLoaded(true);

          const categoryGroupGrouped = groupBy(result, 'categoryGroup');
          const groupedItemLoc = [];

          Object.keys(categoryGroupGrouped).map((item, id) =>
            result.map((item2) => {
              const { name } = item2;

              if (item2.categoryGroup === item) {
                if (groupedItemLoc[id]) {
                  groupedItemLoc[id] = {
                    categoryGroup: item,
                    categoryGroupID: item2.categoryGroupID,
                    category: groupedItemLoc[id]['category'].concat([
                      {
                        name,
                        id: item2.id,
                      },
                    ]),
                  };
                } else {
                  groupedItemLoc[id] = {
                    categoryGroup: item,
                    categoryGroupID: item2.categoryGroupID,
                    amount: Number(item2.amount),
                    category: [
                      {
                        name,
                        id: item2.id,
                      },
                    ],
                  };
                }
              }
              return true;
            })
          );
          setTransactionCategory(groupedItemLoc);
        },
        (error) => {
          setIsTransactionTypeLoaded(true);
          setTransactionError(error);
        }
      );
  }, [transactionType, categoryTreeReload]);

  const incomeShowHandle = () => {
    setTransactionType('income');
    !separateInstance && setTransactionTypeFromCategory('income');
  };
  const expenseShowHandle = () => {
    setTransactionType('expense');
    !separateInstance && setTransactionTypeFromCategory('expense');
  };
  const categoryNameHandle = (name) => setCategoryName(name);

  const editCategoryGroupHandle = (id) => {
    const group = document.getElementById('group' + id);
    const data = {
      user: 1,
      name: group.value,
      type: transactionType === 'expense' ? 'E' : 'I',
      id,
    };
    fetch(`${API}/category/group`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((result) => {
      setCategoryTreeReload(new Date());
    });
  };

  const newCategoryGroupHandle = () => {
    const group = document.getElementById('newCategoryGroupInput');
    const data = {
      type: transactionType === 'expense' ? 'E' : 'I',
      user: 1,
      name: group.value,
    };
    fetch(`${API}/category/group`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((result) => {
      group.value = '';
      setCategoryTreeReload(new Date());
    });
  };

  const deleteCategoryGroupHandle = (id) => {
    const data = {
      user: 1,
      id,
    };
    fetch(`${API}/category/group/checkDelete`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0].records) {
          alert('Nie można usunąć, grupa posiada kategorie');
        } else {
          if (window.confirm('Czy na pewno chcesz usunąć grupę?')) {
            fetch(`${API}/category/group`, {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            }).then((result) => {
              setCategoryTreeReload(new Date());
            });
          }
        }
      });
  };

  const editCategoryHandle = (id) => {
    const category = document.getElementById('category' + id);
    const data = {
      user: 1,
      name: category.value,
      id,
    };
    fetch(`${API}/category`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((result) => {
      setCategoryTreeReload(new Date());
    });
  };

  const newCategoryHandle = (categoryGroup) => {
    const category = document.getElementById('newCategoryInput' + categoryGroup);
    const data = {
      type: transactionType === 'expense' ? 'E' : 'I',
      user: 1,
      name: category.value,
      categoryGroup,
    };
    fetch(`${API}/category`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }).then((result) => {
      category.value = '';
      setCategoryTreeReload(new Date());
    });
  };

  const deleteCategoryHandle = (id) => {
    const data = {
      user: 1,
      id,
    };
    fetch(`${API}/category/checkDelete`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result[0].records) {
          alert('Nie można usunąć, kategoria posiada transakcje');
        } else {
          if (window.confirm('Czy na pewno chcesz usunąć kategorię?')) {
            fetch(`${API}/category`, {
              method: 'DELETE',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data),
            }).then((result) => {
              setCategoryTreeReload(new Date());
            });
          }
        }
      });
  };

  const enableEditingModeHandle = () => {
    setEditMode(!editMode);
  };

  if (transactionError) {
    return <div>Error: {transactionError.message}</div>;
  } else if (!isTransactionTypeLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={styles.wrapper}>
        <div className={styles.categoryTree}>
          <button onClick={incomeShowHandle}>Przychody</button>
          <button onClick={expenseShowHandle}>Wydatki</button>
          {!separateInstance && (
            <>
              <input
                type="checkbox"
                id="enableEditingMode"
                name="enableEditingMode"
                onChange={enableEditingModeHandle}
              />
              <label htmlFor="enableEditingMode"> Tryb edycji</label>
            </>
          )}

          {editMode && (
            <div>
              <input placeholder="Nowa grupa kategorii" id="newCategoryGroupInput" type="text" />
              <button onClick={newCategoryGroupHandle}>Zapisz</button>
            </div>
          )}
          {transactionCategory.map((group) => (
            <div key={group.categoryGroupID} className={styles.mainDiv}>
              <ul>
                {editMode ? (
                  <>
                    <input
                      defaultValue={group.categoryGroup}
                      id={'group' + group.categoryGroupID}
                      type="text"
                    />
                    <button onClick={() => editCategoryGroupHandle(group.categoryGroupID)}>
                      Zapisz
                    </button>
                    <button onClick={() => deleteCategoryGroupHandle(group.categoryGroupID)}>
                      Usuń
                    </button>
                  </>
                ) : (
                  group.categoryGroup
                )}
                {editMode && (
                  <li>
                    <input
                      id={'newCategoryInput' + group.categoryGroupID}
                      placeholder="Nowa kategoria"
                      type="text"
                    />
                    <button onClick={() => newCategoryHandle(group.categoryGroupID)}>Zapisz</button>
                  </li>
                )}
                {group.category.map(
                  (item) =>
                    item.name && (
                      <li key={item.id}>
                        {editMode ? (
                          <>
                            <input defaultValue={item.name} id={'category' + item.id} type="text" />
                            <button onClick={() => editCategoryHandle(item.id)}>Zapisz</button>
                            <button onClick={() => deleteCategoryHandle(item.id)}>Usuń</button>
                          </>
                        ) : (
                          <button
                            onClick={() => {
                              setSelectedCategory(item.id);
                              categoryNameHandle(item.name);
                            }}
                          >
                            {item.name}
                          </button>
                        )}
                      </li>
                    )
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default Category;
