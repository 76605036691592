import React, { useState, useEffect } from 'react';

import TranscationItem from './TransactionItem';
import { API } from '../../index';
import MCDatepicker from 'mc-datepicker';
import TransactionModal from './TransactionModal/TransactionModal';
import { customWeekDays, customMonths } from '../../general';
//fajnie się zapowiada, może zastąpić powyższy https://mymth.github.io/vanillajs-datepicker/#/
//import Datepicker from 'vanillajs-datepicker/Datepicker';

import styles from './Transaction.module.scss';

const Transcation = () => {
  const today = new Date();
  const currentDate = today.toISOString().split('T')[0];
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

  const [transactionError, setTransactionError] = useState(null);
  const [isTransactionLoaded, setIsTransactionLoaded] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [fromDatePicker, setFromDatePicker] = useState(currentDate.substring(0, 8) + '01');
  const [toDatePicker, setToDatePicker] = useState(currentDate.substring(0, 8) + lastDayOfMonth);
  const [minToDatePicker, setMinToDatePicker] = useState();
  const [maxFromDatePicker, setMaxFromDatePicker] = useState();
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [transactionData, setTransactionData] = useState([]); //tu
  const [isAccountLoaded, setIsAccountLoaded] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [account, setAccount] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [isSelectedTransaction, setIsSelectedTransaction] = useState(false);

  const minDate = new Date(minToDatePicker);
  const maxDate = new Date(maxFromDatePicker);

  useEffect(() => {
    fetch(`${API}/transaction`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: 1,
        dateFrom: fromDatePicker,
        dateTo: toDatePicker,
        account: selectedAccount,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsTransactionLoaded(true);
          setTransaction(result);
        },
        (error) => {
          setIsTransactionLoaded(true);
          setTransactionError(error);
        }
      );
  }, [fromDatePicker, toDatePicker, transactionData, selectedAccount]);

  useEffect(() => {
    fetch(`${API}/account/1`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAccountLoaded(true);
          setAccount(result);
        },
        (error) => {
          setIsAccountLoaded(true);
          setAccountError(error);
        }
      );
  }, []);

  if (transactionError || accountError) {
    return (
      <div>
        Error: {transactionError.message} {accountError.message}
      </div>
    );
  } else if (!isTransactionLoaded || !isAccountLoaded) {
    return <div>Loading...</div>;
  } else {
    //date picker documentation https://mcdatepicker.netlify.app/
    const fromPicker = MCDatepicker.create({
      el: '#datepickerFrom',
      bodyType: 'inline',
      dateFormat: 'yyyy-mm-dd',
      autoClose: true,
      customWeekDays,
      customMonths,
      customClearBTN: '',
      customCancelBTN: 'Anuluj',
      firstWeekday: 1,
      closeOnBlur: true,
      maxDate,
      selectedDate: new Date(fromDatePicker),
    });
    const toPicker = MCDatepicker.create({
      el: '#datepickerTo',
      bodyType: 'inline',
      dateFormat: 'yyyy-mm-dd',
      autoClose: true,
      customWeekDays,
      customMonths,
      customClearBTN: '',
      customCancelBTN: 'Anuluj',
      firstWeekday: 1,
      closeOnBlur: true,
      minDate,
      selectedDate: new Date(toDatePicker),
    });

    const handleInputFromClick = () => fromPicker.open();
    const handleInputToClick = () => toPicker.open();

    fromPicker.onClose(() => {
      if (fromPicker.getFormatedDate()) {
        setFromDatePicker(fromPicker.getFormatedDate());
        setMinToDatePicker(fromPicker.getFormatedDate());
      }
      unselectTransaction();
    });

    toPicker.onClose(() => {
      if (toPicker.getFormatedDate()) {
        setToDatePicker(toPicker.getFormatedDate());
        setMaxFromDatePicker(toPicker.getFormatedDate());
      }
      unselectTransaction();
    });

    const transactionModalOpenAddHandle = (e) => {
      e.preventDefault();
      unselectTransaction();
      setTransactionData([]); //tu
      setIsTransactionModalOpen(true);
    };

    const transactionModalOpenEditHandle = (e) => {
      e.preventDefault();
      setIsTransactionModalOpen(true);
    };

    const transactionModalClose = (e) => {
      e.preventDefault();
      //console.log(e.target[0].value);
      setIsTransactionModalOpen(false);
      unselectTransaction();
    };

    const updateTransactionData = (data, operationMode) => {
      fetch(`${API}/transaction/${operationMode}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      setTransactionData(data);
      unselectTransaction();
    };

    const getSelectedAccount = () => {
      const selectedAccount = Number(document.getElementById('selectTransactionAccount').value);
      selectedAccount ? setSelectedAccount(selectedAccount) : setSelectedAccount(0);
      setIsSelectedTransaction(false);
      unselectTransaction();
    };

    const dataAfterSelectedTransaction = (data) => {
      if (isSelectedTransaction && transactionData.id !== data.id) {
        document.getElementById(isSelectedTransaction).style.backgroundColor = '';
      }
      setTransactionData(data);
    };

    const unselectTransaction = () => {
      if (isSelectedTransaction)
        document.getElementById(isSelectedTransaction).style.backgroundColor = '';
      // setTransactionData(null);
      setIsSelectedTransaction(false);
    };

    const transactionDeleteHandle = (e) => {
      e.preventDefault();
      if (window.confirm('Czy na pewno chcesz usunąć transakcję?')) {
        fetch(`${API}/transaction`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(transactionData),
        });
        setTransactionData([]);
        unselectTransaction();
      }
    };

    let balance = 0;

    return (
      <div className="startViewElementsWrapper" id={styles.div}>
        <>
          <h3>Operacje</h3>
          <form className={styles.transactionForm}>
            <label>Analiza za okres od </label>
            <input
              id="datepickerFrom"
              type="text"
              readOnly
              onClick={handleInputFromClick}
              value={fromDatePicker}
              className="inputDate"
            />
            <label> do </label>
            <input
              id="datepickerTo"
              type="text"
              readOnly
              onClick={handleInputToClick}
              value={toDatePicker}
              className="inputDate"
            />
            <select id="selectTransactionAccount" onChange={getSelectedAccount}>
              <option value="Wszystkie konta">Wszystkie konta</option>
              {account.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            <button onClick={transactionModalOpenAddHandle}>Dodaj</button>
            {isSelectedTransaction && (
              <>
                <button onClick={transactionModalOpenEditHandle}>Edytuj</button>
                <button onClick={transactionDeleteHandle}>Usuń</button>
              </>
            )}
          </form>
        </>
        {transaction.length ? (
          <>
            <div className={styles.itemList} id="transactionUl">
              <TranscationItem
                date="Data"
                account="Konto"
                categoryGroup="Grupa kategorii"
                category="Kategoria"
                description="Opis"
                income="Przychód"
                expense="Wydatek"
                balance="Saldo"
              />
              {transaction.map((item, id) => (
                <TranscationItem
                  key={id}
                  {...item}
                  balance={(balance += Number(item.amount))}
                  setIsSelectedTransaction={setIsSelectedTransaction}
                  dataAfterSelectedTransaction={dataAfterSelectedTransaction}
                />
              ))}
            </div>
          </>
        ) : (
          <p className={styles.noItems}>W wybranym okresie nie ma żadnych danych!</p>
        )}
        {isTransactionModalOpen && (
          <TransactionModal
            transactionModalClose={transactionModalClose}
            updateTransactionData={updateTransactionData}
            account={account}
            transactionData={transactionData}
          />
        )}
      </div>
    );
  }
};

export default Transcation;
