import React from 'react';

import styles from './LastMonthItem.module.scss';

const LastMonthItem = ({ categoryGroup, amount, transaction }) => {
  return (
    <li>
      <div className={styles.groupHeader}>
        <span>{categoryGroup}</span>
        <span className="startViewElementsWrapperAmount">{amount.toFixed(2)}</span>
      </div>
      <div className={styles.groupItem}>
        {transaction.map(({ account, amount, category, date, description, id }) => (
          <p key={id}>
            <span className={styles.itemAccount}>{account}</span>
            <span className={styles.itemCategory}>{category}</span>
            <span className={styles.itemDescription}>{description}</span>
            <span className={styles.itemDate}>{date}</span>
            <span className={styles.itemAmount}>{amount}</span>
          </p>
        ))}
      </div>
    </li>
  );
};

export default LastMonthItem;
