import React from 'react';

import Transcation from '../../components/Transaction/Transaction';

import styles from './TransactionView.module.scss';

const TransactionView = () => (
  <div className={styles.wrapper}>
    <div className={styles.itemWrapper}>
      <Transcation />
    </div>
  </div>
);

export default TransactionView;
