import React from "react";
import ReactDOM from "react-dom";

import Root from "./views/Root/Root";

const localAPI = false;
export const API = localAPI
  ? "http://localhost:2000"
  : // : "https://node.ravnet.eu";
    "https://n1.sbit.net.pl";

export const userID = "1";
export const KEY = "dHd63Hsh2Sj2jkaaA1" + userID;
ReactDOM.render(<Root />, document.getElementById("root"));
